var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "table-responsive mt-3 mb-4"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v(_vm._s(_vm.$t("property-type2")))]), _c('th', [_vm._v(_vm._s(_vm.$t("details")))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', [_vm._l(_vm.properties, function (property, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(property.category))]), _c('td', [property.type == 'immoveable' ? _c('router-link', {
      attrs: {
        "to": {
          name: 'trust-inheritance.immoveable-property.details',
          query: {
            hibah_id: _vm.hibahId,
            property_id: property.id
          }
        }
      }
    }, [_c('span', [_vm._v(_vm._s(property.property_no))])]) : _c('router-link', {
      attrs: {
        "to": {
          name: 'trust-inheritance.moveable-property.details',
          query: {
            hibah_id: _vm.hibahId,
            property_id: property.id
          }
        }
      }
    }, [property.category == 'Kenderaan' ? _c('span', [_vm._v(_vm._s(property.transport_model))]) : _c('span', [_vm._v(_vm._s(property.institution_name))])])], 1), _c('td', {
      staticClass: "text-right"
    }, [_vm._v("RM " + _vm._s(property.property_value))])]);
  }), _vm.properties.length > 0 ? _c('tr', [_c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("total")))])]), _c('td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v("RM " + _vm._s(_vm.totalValue))])])]) : _vm._e(), _vm.properties.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_c('h6', {
    staticClass: "mt-3 mb-3"
  }, [_vm._v(_vm._s(_vm.$t("no-prop")))])])]) : _vm._e()], 2)])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.openModalTransaction
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("transaction")) + " ")])], 1), _c('div', {
    staticClass: "table-responsive mt-3 mb-4"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v(_vm._s(_vm.$t("type")))]), _c('th', [_vm._v(_vm._s(_vm.$t("total")))]), _c('th', [_vm._v(_vm._s(_vm.$t("current-total")))]), _c('th', [_vm._v(_vm._s(_vm.$t("balance-aft")))]), _c('th', [_vm._v(_vm._s(_vm.$t("description")))]), _c('th', [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm._l(_vm.productTransactions, function (transaction, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(transaction.type == 1 ? "Debit" : "Credit"))]), _c('td', [_vm._v("RM " + _vm._s(transaction.amount))]), _c('td', [_vm._v("RM " + _vm._s(transaction.balance_before))]), _c('td', [_vm._v("RM " + _vm._s(transaction.balance_after))]), _c('td', [_vm._v(_vm._s(transaction.description))]), _c('td', [_c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteProductTransaction(transaction.id, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")])], 1)]);
  }), _vm.productTransactions.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_c('h6', {
    staticClass: "mt-3 mb-3"
  }, [_vm._v(_vm._s(_vm.$t("no-transactions")))])])]) : _vm._e()], 2)])])]), _c('b-modal', {
    ref: "modalAddTransaction",
    staticClass: "modal fade",
    attrs: {
      "no-enforce-focus": true,
      "title": "Tambah Transaksi",
      "ok-title": _vm.$t('add'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.submitForm
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "type"
    }
  }, [_vm._v(_vm._s(_vm.$t("transaction-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.type,
      expression: "form.type"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("- " + _vm._s(_vm.$t("pls-select-transaction-type")) + " -")]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Debit")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("credit")))])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "total"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amount,
      expression: "form.amount"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amount", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "balance_before"
    }
  }, [_vm._v(_vm._s(_vm.$t("balance-aft")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.balance_before,
      expression: "form.balance_before"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.balance_before
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "balance_before", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "balance_after"
    }
  }, [_vm._v(_vm._s(_vm.$t("balance-aft")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.balance_after,
      expression: "form.balance_after"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.balance_after
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "balance_after", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "description"
    }
  }, [_vm._v(_vm._s(_vm.$t("description")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.description,
      expression: "form.description"
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "2"
    },
    domProps: {
      "value": _vm.form.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "description", $event.target.value);
      }
    }
  })])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }